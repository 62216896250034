import React from 'react';
import Avatar from './Avatar';
import { Link } from 'react-router-dom';

const UserCard = ({ children, user, border, handleClose, setShowFollowers, setShowFollowing, msg, style }) => {
    const handleCloseAll = () => {
        if (handleClose) handleClose();
        if (setShowFollowers) setShowFollowers(false);
        if (setShowFollowing) setShowFollowing(false);
    };

    return (
        // <div className={`d-flex p-2 align-items-center justify-content-between w-100 ${border || ''}`}>
        <div className={`d-flex p-2 align-items-center justify-content-between w-100`}>
            <div>
                <Link
                    to={`/profile/${user._id}`}
                    onClick={handleCloseAll}
                    className="d-flex align-items-center"
                    style={{ textDecoration: 'none', ...style }}
                >
                    <Avatar src={user.avatar} size="big-avatar" />

                    <div className="ml-1" style={{ transform: 'translateX(5px)' }}>
                        <span
                            className="d-block"
                            style={{
                                textDecoration: 'none',
                                ...style, // Apply additional style here
                            }}
                        >
                            <b>{user.username}</b>
                        </span>
                    </div>
                </Link>
            </div>
            {children}
        </div>
    );
};

export default UserCard;
