import React, { useState } from 'react';
import Avatar from '../Avatar';
import { videoShow } from '../../utils/mediaShow';
import { useSelector, useDispatch } from 'react-redux';
import { deleteMessages } from '../../redux/actions/messageAction';
import Times from './Times';

const MsgDisplay = ({ user, msg, theme, data }) => {
    const { auth } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [enlargeImage, setEnlargeImage] = useState(null);

    const handleDeleteMessages = () => {
        if (!data || !msg._id) return;

        if (window.confirm('Do you want to delete?')) {
            dispatch(deleteMessages({ msg, data, auth }));
        }
    };

    const handleImageClick = (url) => {
        setEnlargeImage(url);
    };

    const closeEnlargedImage = () => {
        setEnlargeImage(null);
    };

    return (
        <div className="msg_display">
            <div
                className="chat_title"
                // style={{
                //     display: 'flex',
                //     justifyContent: 'flex-end',
                //     alignItems: 'center',
                // }}
            >
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Avatar src={user.avatar} size="small-avatar" />
                    <span style={{ marginTop: '2px' }}>
                        <b>{user.username}</b>
                    </span>

                    {user._id === auth.user._id && (
                        <i
                            className="fas fa-trash text-danger"
                            onClick={handleDeleteMessages}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    )}
                </div>
            </div>

            <div className="you_content" style={{ textAlign: 'left' }}>
                <div>
                    {msg.text && (
                        <div
                            className="chat_text"
                            style={{
                                filter: theme ? 'invert(1)' : 'invert(0)',
                                border: 'none',
                                marginLeft: 'auto',
                                marginRight: '0',
                            }}
                        >
                            {msg.text}
                        </div>
                    )}
                    {msg.media.map((item, index) => (
                        <div key={index}>
                            {item.url.match(/video/i)
                                ? videoShow(item.url, theme)
                                : (
                                    <img
                                        src={item.url}
                                        alt="media"
                                        className="message-image"
                                        onClick={() => handleImageClick(item.url)}
                                        style={{ cursor: 'pointer', maxWidth: '100%', marginLeft: '0', marginRight: '0', border: 'none' }}
                                    />
                                )}
                        </div>
                    ))}
                </div>

                {msg.call && (
                    <button
                        className="btn d-flex align-items-center py-3"
                        style={{
                            background: '#31005d',
                            color: 'white',
                            borderRadius: '10px',
                            marginLeft: 'auto',
                            marginRight: '0',
                        }}
                    >
                        <span
                            className="material-icons font-weight-bold mr-1"
                            style={{
                                fontSize: '2.5rem',
                                color: msg.call.times === 0 ? 'crimson' : 'green',
                                filter: theme ? 'invert(1)' : 'invert(0)',
                            }}
                        >
                            {msg.call.times === 0
                                ? msg.call.video
                                    ? 'videocam_off'
                                    : 'phone_disabled'
                                : msg.call.video
                                ? 'video_camera_front'
                                : 'call'}
                        </span>
                        <div className="text-left">
                            <h6>{msg.call.video ? 'Video Call' : 'Audio Call'}</h6>
                            <small>
                                {msg.call.times > 0 ? (
                                    <Times total={msg.call.times} />
                                ) : (
                                    new Date(msg.createdAt).toLocaleTimeString()
                                )}
                            </small>
                        </div>
                    </button>
                )}
            </div>

            <div className="chat_time" style={{ textAlign: 'left'}}>
                {new Date(msg.createdAt).toLocaleString()}
            </div>

            {/* Enlarged Image Modal */}
            {enlargeImage && (
                <div className="enlarge-image-container" onClick={closeEnlargedImage}>
                    <div className="enlarge-image-wrapper">
                        <img src={enlargeImage} alt="Enlarged" className="enlarge-image" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MsgDisplay;